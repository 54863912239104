import { Route, Routes, useLocation } from 'react-router-dom';
import './App.scss';
import Wholesale from './pages/wholesale/Wholesale';
import Contact from './pages/contact/Contact';
import Main from './pages/main/Main';
import Blogs from './pages/blogs/Blogs';
import NewBlog from './pages/blogs/sub_pages/new_blog/NewBlog';
import BlogDetail from './pages/blogs/sub_pages/blog_detail/BlogDetail';
import Admin from './pages/admin/Admin';
import Receipt from './pages/receipt/Receipt';
import ReceiptDetail from './pages/receipt/sub-pages/receipt_detail/ReceiptDetail';
import ReceiptCheck from './pages/receipt/sub-pages/receipt_check/ReceiptCheck';
import AlwaysLowestPrices from './pages/campaign/always-lowest-prices/AlwaysLowestPrices';
import {Form as ALPForm} from './pages/campaign/always-lowest-prices/sub-pages/form/Form';
import BotDemo from './pages/bot-demo/BotDemo';
import PersonalBot from './pages/personal-bot/PersonalBot';
import PickList from './pages/pickList/PickList';
import StoreList from './pages/store-list/StoreList';
import SelfQuotation from './pages/self-quotation/SelfQuotation';
import SelfQuotationCreate from './pages/self-quotation/sub-page/self-quotation-create/SelfQuotationCreate';
import NotFoudPage from './pages/404NotFound/404NotFound';

function App() {
  let location = useLocation();
  return (
    <div className="App">
      <Routes location={location}>
        <Route path="/" element={<Main />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/new" element={<NewBlog />} />
        <Route path="/blog/detail/:id" element={<BlogDetail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/wholesale" element={<Wholesale />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/receipt" element={<Receipt />} />
        <Route path='/receipt/detail/:id' element={<ReceiptDetail />} />
        <Route path='/receipt/check/:id' element={<ReceiptCheck />} />
        <Route path="/self-quotation" element={<SelfQuotation />} />
        <Route path="/self-quotation/:phone_number" element={<SelfQuotationCreate />} />
        <Route path='/campaign/always-lowest-prices' element={<AlwaysLowestPrices />} />
        <Route path='/campaign/always-lowest-prices/form' element={<ALPForm />} />
        {/* <Route path="/bot-demo" element={<BotDemo />} />
        <Route path="/personal-bot" element={<PersonalBot />} /> */}
        <Route path="/internal/picklist" element={<PickList />} />
        <Route path="/internal/store-list" element={<StoreList />} />
        {/* 404 Not found */}
        <Route path="*" element={<NotFoudPage />} />
      </Routes>

    </div>
  );
}

export default App;
