import "./PickList.scss";
import "../../styles/Text.scss";
import React, { useEffect, useRef } from 'react'
import { getOrderList, getProductImage } from "../../utils/atrobot-api";
import useState from "react-usestateref";
import Spinner from '../../components/spinner/Spinner';
import PickinglistOnline from "./sub-components/Packinglist/PickinglistOnline";
import * as FunctionStatusAPI from '../../utils/API/FunctionStatus';

export const prepareOrderList = async (rawData) => {
    const data = rawData;
    // Get image for each prouct
    const productList = await Promise.all(data.OrderDetails.map(async (item) => {
        const result = await getProductImage(item.ProductID, 512)
        const newItem = { ...item }
        newItem.image = result
        return newItem
    }))
    const newData = { ...data }
    newData.OrderDetails = productList
    // Decodes a string of Base64-encoded data into bytes
    // rawData.ShopeeAWB PDF
    if (rawData?.ShopeeAWB !== undefined) {
        const base64 = rawData.ShopeeAWB
        const buffer = Buffer.from(base64, 'base64')

        const blob = new Blob([
            buffer
        ], { type: 'application/pdf' })
        const url = URL.createObjectURL(blob)
        newData.pdfUrl = url
        newData.pdfBinary = buffer
    }

    return newData
}
export default function PickList() {
    const [raw_data, setRawData, raw_data_ref] = useState([]);
    const packingListRef = useRef(null);
    const [isDisablePickingList, setIsDisablePickingList, isDisablePickingListRef] = useState(false);

    useEffect(() => {
        FunctionStatusAPI.getStatus("interval_one_hour").then(res => {
            const result = res.data.message.running;
            if (result) {
                setIsDisablePickingList(true);
            }
        });
        // Fetch all order
        fetchOrderList(setRawData).catch(err => {
            alert(err)
        });
    }, [])
    return (
        <div className="picklist"
            style={{
            }}
        >
            {isDisablePickingList ?
                <div className="announce text-bold text-big">
                    <div>Picklist is updating, please try again later</div>
                    <div>กำลังอัพเดท Picklist กรุณาลองใหม่อีกครั้งในภายหลัง</div>

                    {/* Refresh button */}
                    <div className="refresh-button" onClick={() => {
                        // Reload page
                        window.location.reload();
                    }}>
                        <div>Refresh กดเพื่อรีเฟรช</div>
                        <i className="fa-solid fa-refresh"></i>
                    </div>

                </div> :
                <div className="content-container"


                >
                    <div className="picklist-content"
                    >

                        {raw_data_ref.current.length === 0 ?
                            <div className="loading-container">
                                <div className="loading">
                                    <Spinner customText="Loading" />
                                </div>
                            </div> :
                            <>
                                <PickinglistOnline
                                    data={raw_data}
                                    setRef={packingListRef}

                                />
                            </>
                        }
                    </div>
                </div>
            }
        </div>
    )

}

async function fetchOrderList(setRawData) {
    await getOrderList().then(res => {
        setRawData(res.data['data']);
    });
}




