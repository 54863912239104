import React, { useRef } from 'react'
import './404NotFound.scss'

export default function NotFoudPage() {

    return (
        <div
            className='pages-form-submitted'
        >
            <div className="pages-form-submitted-wrapper">
                <div className="panel">
                    <div className="title">
                        404 Not Found
                    </div>
                    <div className="body">
                        <div className="message">
                            ไม่พบหน้าที่คุณต้องการ
                        </div>
                    </div>

                </div>
                <div className="logo">
                    <img src="/logo.jpg" alt="logo" />
                </div>
            </div>
        </div>
    )
}
