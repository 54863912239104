import React, { useState } from 'react'
import './PickinglistOnline.scss'
import * as AtrobotAPI from '../../../../utils/atrobot-api'
import { useEffect } from 'react';
import { NOT_PACKED_STATUSES } from '../../Defined';
import BlinkingUnderLineNumber from '../../../../components/BlinkingUnderLineNumber/BlinkingUnderLineNumber';
import '../../../../styles/Text.scss';
import * as sku_utils from '../../../../utils/SkuUtils';
import ReactTooltip from 'react-tooltip';

export default function PickinglistOnline({ data, setRef }) {
    const [processedData, setProcessedData] = React.useState([]);

    const [isScreenWidth, setIsScreenWidth] = useState(window.innerWidth > 1000);

    useEffect(() => {
        const handleResize = () => {
            setIsScreenWidth(window.innerWidth > 1000);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(
        () => {
            processData(data).then(
                res => {
                    // Using productCode as key
                    const res_json = {}
                    res.forEach(item => {
                        res_json[item.productCode] = item
                    })
                    setProcessedData(res)
                }
            )
        }
        , [data])
    return (
        <>
            <PackingListToPrint data={processedData} ref={setRef} isScreenWidth={isScreenWidth} />
        </>
    )
}


class PackingListToPrint extends React.PureComponent {
    render() {
        const processed = this.props.data;
        const isScreenWidth = this.props.isScreenWidth;
        return (
            <div className="packing-list-to-print"
                style={{}}
            >
                {/* Simple header with date */}
                <div className="packing-list-header">
                    <div className="header-wrapper bold size-big">
                        Packing List - {new Date().toLocaleDateString()}
                    </div>
                </div>
                {/* Packing list content */}
                <div className="packing-list-content">
                    <div className="packing-list-content-wrapper">
                        <div className="packing-list-item" key={"A"}>
                            <div className="packing-list-item-wrapper">
                                <div className="index">
                                    #
                                </div>
                                <div className="product-image">
                                    Image
                                </div>
                                <div className="code">
                                    Code
                                </div>
                                {isScreenWidth &&
                                    <>
                                        <div className="name">
                                            Name
                                        </div>
                                    </>
                                }
                                <div className="attr">
                                    Attr
                                </div>
                                <div className="qty">
                                    Qty
                                </div>
                            </div>
                        </div>

                        {Object.keys(processed).map((key, index) => {
                            const item = processed[key];
                            return (
                                <div className="packing-list-item" key={item.productCode}
                                >
                                    <ReactTooltip />
                                    <div className="packing-list-item-wrapper">
                                        <div className="index text-bold">
                                            <BlinkingUnderLineNumber blink_type='>=' text={item.item_num} value={item.qty} blink_value_threshold={2} color={item.qty_color} note={item.warning_note} />
                                        </div>
                                        <div className="product-image">
                                            <img src={item.image} alt="product" />
                                        </div>
                                        <div className="code">
                                            <div className="text-bold">
                                                <BlinkingUnderLineNumber blink_type='>=' text={item.productCode} value={item.qty} blink_value_threshold={2} color={item.qty_color} note={item.warning_note} />
                                            </div>
                                            <BlinkingUnderLineNumber blink_type='>=' text={sku_utils.codeMapperNumber(item.productCode)} value={item.qty} blink_value_threshold={2} color={item.qty_color} note={item.warning_note} />
                                        </div>
                                        {
                                            isScreenWidth &&
                                            <>
                                                <div className="name">
                                                    {item.name}
                                                </div>
                                            </>
                                        }
                                        <div className="attr">
                                            {item.attr.map((attrItem, attrIndex) => {
                                                return (
                                                    <div className="attr-item" key={attrIndex}>
                                                        {attrItem.AttrValueName}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div
                                            data-tip={'เหลือสินค้า : ' + (item.deducted_stock ? (item.current_stock + item.qty) : (item.current_stock)) + ' ชิ้น'}
                                            className="qty text-bold">
                                            <BlinkingUnderLineNumber blink_type='>=' value={item.qty} blink_value_threshold={2} color={item.qty_color} note={item.warning_note} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

async function processData(data) {
    // Merge the quantity of the same product
    let processed = {};
    console.log('pre_process', data)
    data.map((item) => {
        const variation = item.variation;
        const order_staus = item.status;
        if (!NOT_PACKED_STATUSES.includes(order_staus)) {
            return;
        }

        const deducted_stock = item.deducted_stock;
        variation.map((variationItem) => {
            console.log(variationItem)
            const productCode = variationItem.ProductCode;
            if (processed[productCode]) {
                processed[productCode].qty += variationItem.Qty;
            }
            else {
                var qty_color = 'black';
                var warning_note = '';
                if (variationItem.weight === 'None' || variationItem.volume === 'None'
                    || variationItem.weight === '' || variationItem.volume === ''
                    || variationItem.weight === null || variationItem.volume === null
                    || variationItem.weight === undefined || variationItem.volume === undefined
                    || parseFloat(variationItem.weight) === 0 || parseFloat(variationItem.volume) === 0
                ) {
                    // Darker yellow
                    qty_color = '#d9b500';
                    warning_note = 'Warning: No weight or volume';
                }
                processed[productCode] = {
                    qty: variationItem.Qty,
                    name: variationItem.Name,
                    productID: variationItem.ProductID,
                    attr: variationItem.Attr,
                    weight: variationItem.weight,
                    volume: variationItem.volume,
                    qty_color: qty_color,
                    warning_note: warning_note,
                    current_stock: variationItem.current_stock,
                    deducted_stock: deducted_stock
                }
            }
        })
    })
    // Get the image of the product preserved the product code
    processed = await Promise.all(Object.keys(processed).map(async (key, item_index) => {
        const item = processed[key];
        const result = await AtrobotAPI.getProductImage(item.productID, 256)
        item.image = result
        item.productCode = key
        item.item_num = item_index + 1
        return item
    }))
    // Sort the product by product code
    processed = processed.sort((a, b) => {
        return a.productCode.localeCompare(b.productCode)
    })
    // Add the item number
    processed = processed.map((item, index) => {
        item.item_num = index + 1
        return item
    })

    return processed;
}